.nav {
  background-color: #003333;
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.nav-ul {
  list-style: none;
  display: flex;
  padding: 0;
}

.nav-li {
  display: flex;
  align-items: center;
  padding: 0 12px 0 12px;
}

.nav-li-right {
  order: 1;
  margin-left: auto;
}

.logo {
  color: white;
  margin: 0;
}

.nav-link {
  color: white;
  font-weight: 600;
}

.page-content {
  margin: 24px;
}

.user-email {
  color: white;
}
